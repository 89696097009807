<template>
  <v-container id="products" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-row>
      <v-col class="text-right">
        <app-btn @click="openDialog()">
          <v-icon>mdi-view-grid-plus</v-icon> Add New Product
        </app-btn>
        <app-btn class="mr-7 ml-2" @click="dialogUpload = true">
          <v-icon>mdi-view-grid-plus</v-icon> Product Bulk Upload
        </app-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <material-card
          icon="mdi-dropbox"
          icon-small
          color="primary"
          title="Products"
        >
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Search records"
              single-line
              style="max-width: 250px"
            />

            <v-divider class="mt-3" />

            <v-data-table
              :headers="headers"
              :items="products"
              :options.sync="options"
              :search.sync="search"
              :server-items-length="totalProducts"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15],
              }"
            >
              <template v-slot:[`item.image`]="{ item }">
                <img
                  v-if="item.sku != ''"
                  :id="'product_img_' + item.id"
                  :src="`https://res.cloudinary.com/diqfjkeck/image/upload/v1625110734/mymc-web/pack-images/${item.sku}_P.jpg`"
                  alt="No Image"
                  :width="80"
                  @mouseover="imageEnlarge('product_img_' + item.id)"
                  @mouseout="imageReset('product_img_' + item.id)"
                />
                <div v-else class="ml-2">No Image</div>
              </template>
              <template v-slot:[`item.categoryId`]="{ item }">
                <div v-if="categories && item.categoryId > 0">
                  {{
                    categories.find((x) => x.categoryId === item.categoryId)
                      .name
                  }}
                </div>
              </template>
              <template v-slot:[`item.bayId`]="{ item }">
                <div v-if="item.bayId > 0">
                  {{ zones.find((x) => x.bayId === item.bayId).name }}
                </div>
              </template>
              <template v-slot:top>
                <v-dialog v-model="dialogUpload" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Products Bulk Upload</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container class="mt-3">
                        <v-row>
                          <v-file-input
                            v-model="productCSVFile"
                            label="Product File (.csv)"
                          />
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogUpload = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="upload">
                        Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form ref="ProductForm">
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.name"
                                label="Product Name"
                                :rules="[rules.required]"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.ean"
                                label="Barcode"
                                :rules="[rules.required]"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.sku"
                                label="SKU"
                                :rules="[rules.required]"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.image"
                                label="Image"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.productType"
                                label="Product Type"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="editedItem.type"
                                :items="[
                                  { text: 'Product', value: 1 },
                                  { text: 'Sample product', value: 3 },
                                ]"
                                item-text="text"
                                item-value="value"
                                label="Type"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="editedItem.categoryId"
                                :items="categories"
                                item-text="name"
                                item-value="categoryId"
                                label="Category"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="editedItem.bayId"
                                :items="zones"
                                item-text="name"
                                item-value="bayId"
                                label="Bays"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="editedItem.replacementProductId"
                                :items="AllProducts"
                                item-text="name"
                                item-value="ean"
                                label="Replacement Product"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="editedItem.isOutOfStock"
                                :items="stockStatus"
                                item-text="text"
                                item-value="isOutOfStock"
                                label="Stock Status"
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue darken-1" text @click="closeDelete">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  medium
                  color="info"
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon medium color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:[`item.outOfStock`]="{ item }">
                <v-icon v-if="item.outOfStock" color="error" large>
                  mdi-close-circle
                </v-icon>
                <v-icon v-else color="primary" large> mdi-check-circle </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProductDataService from "@/services/ProductDataService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Products",
  components: { Loading },
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: false,
    loading: false,
    imgSize: 60,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    successAlert: true,
    successMessage: null,
    totalProducts: 0,
    loading: false,
    options: {},
    search: null,
    awaitingSearch: null,
    products: [],
    AllProducts: [],
    categories: [],
    zones: [],
    stockStatus: [
      { text: "In Stock", isOutOfStock: false },
      { text: "Out of Stock", isOutOfStock: true },
    ],
    productCSVFile: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      ean: "",
      sku: "",
      image: "",
      productType: "",
      type: 1,
      categoryId: "",
      bayId: "",
      replacementProductId: "",
      isOutOfStock: "",
    },
    defaultItem: {
      name: "",
      ean: "",
      sku: "",
      image: "",
      productType: "",
      type: 1,
      categoryId: "",
      bayId: "",
      replacementProductId: "",
      isOutOfStock: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Product" : "Edit Product";
    },
    headers() {
      return [
        { text: "Image", align: "start", value: "image", sortable: false },
        { text: "Product Name", value: "name" },
        { text: "Barcode", value: "ean" },
        { text: "SKU", value: "sku" },
        { text: "Category", value: "categoryId" },
        { text: "Bay", value: "bayId" },
        { text: "Replacement Product", value: "replacementProductEan" },
        { text: "Stock Status", align: "center", value: "outOfStock" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi(this.search);
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = setTimeout(() => {
          this.getDataFromApi(this.search);
        }, 1000);
      },
    },
  },
  created() {
    this.$http.get("Categories").then((response) => {
      this.categories = response.data.data;
    });
    this.$http.get("Bays").then((response) => {
      const data = response.data.data;
      this.zones = data.sort((a, b) => {
        let A = a.name.split(" ")[1];
        let B = b.name.split(" ")[1];
        return A - B;
      });
    });

    //this.getDataFromApi();
  },
  methods: {
    openDialog() {
      this.dialog = true;
      if (!(this.AllProducts && this.AllProducts.length > 0)) {
        this.$http.get("Products").then((response) => {
          this.AllProducts = response.data.data;
        });
      }
    },
    getDataFromApi(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        pageNumber: page,
        pageSize: itemsPerPage,
        search: search,
      };
      this.$http
        .post("Products/Paginated", data)
        .then((response) => {
          this.products = response.data.data.items;
          this.totalProducts = response.data.data.totalCount;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.type = 1;
      this.editedItem.isOutOfStock = item.outOfStock;
      this.editedItem.replacementProductId = item.replacementProductEan;
      this.openDialog();
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`Products/${this.editedItem.productId}`)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.products.splice(index, 1);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.ProductForm.validate()) {
        if (this.editedIndex > -1) {
          let index = this.editedIndex;
          this.$http
            .put(`Products/${this.editedItem.productId}`, this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                Object.assign(this.products[index], this.editedItem);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            });
        } else {
          this.$http.post("/Products", this.editedItem).then((response) => {
            if (response.data.success) {
              this.products.unshift(response.data.data);
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          });
        }
        this.close();
      }
    },
    upload() {
      if (this.productCSVFile) {
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("File", this.productCSVFile);
        ProductDataService.upload(bodyFormData)
          .then((response) => {
            if (response.data.success) {
              this.getDataFromApi(this.search);
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          });
      } else {
        this.$toast.error("No file selected.", "Error", {
          position: "topRight",
        });
      }
      this.dialogUpload = false;
    },
    imageEnlarge: function (id) {
      document.getElementById(id).style.height = "500px";
      document.getElementById(id).style.width = "500px";
    },
    imageReset: function (id) {
      document.getElementById(id).style.height = "80px";
      document.getElementById(id).style.width = "80px";
    },
    getCategory(id) {
      let category = this.categories.find((x) => x.id == id);
      if (category) {
        return category.name;
      } else {
        return "";
      }
    },
    getZone(id) {
      let zone = this.zones.find((x) => x.id == id);
      if (zone) {
        return zone.name;
      } else {
        return "";
      }
    },
  },
};
</script>
